<template>
  <div
    @click.stop
    class="table-text-input-cell"
  >
    <span
      v-if="!isInputActive"
      @click.stop="setInputActive"
      class="table-label-cell"
      :class="{'has-no-value': !hasValue}"
      v-html="displayValue"
    />
    <TextInput
      v-else
      class="text-input"
      :model-value="value"
      no-clear-button
      autofocus
      @update:modelValue="updateValue"
      @unfocus="setInputInactive"
    />
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { isNil } from 'lodash';
import TextInput from '@/components/common/TextInput';

export default {
  emits: ['updateValue'],
  components: {
    TextInput,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hasValue = computed(() => !isNil(props.row?.[props.columnKey]));
    const value = computed(() => props.row?.[props.columnKey]);
    const displayValue = computed(() => value.value ?? '-');
    const updatedValue = ref(value.value);
    const isInputActive = ref(false);

    const updateValue = (val) => {
      updatedValue.value = val;
    };
    const setInputActive = () => { isInputActive.value = true; };
    const setInputInactive = () => {
      isInputActive.value = false;
      const wholeNumberRegex = /^-?\d+$/;
      if (props.columnKey === 'position' && !wholeNumberRegex.test(updatedValue.value)) return;
      if (value.value !== updatedValue.value) {
        emit('updateValue', {
          ...props.row,
          [props.columnKey]: props.columnKey === 'position' ? parseInt(updatedValue.value, 10) : updatedValue.value,
        });
      }
    };

    return {
      displayValue,
      value,
      hasValue,
      isInputActive,
      setInputActive,
      setInputInactive,
      updateValue,
    };
  },
};
</script>
<style lang="scss">
  .table-text-input-cell {
    width: 100%;
    display: flex;
    .table-label-cell {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $black;
      &.has-no-value {
        color: $gray600;
      }
    }
    .text-input {
      padding: 2px 0;
    }
  }
</style>
