export const marketGroupsConfigurationsTableColumns = () => ({
  code: {
    label: 'Code',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    minWidth: 300,
  },
  displayName: {
    label: 'Display name',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
  },
  position: {
    label: 'Position',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
  },
  active: {
    label: 'Active',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
    maxWidth: 150,
  },
  actions: {
    label: '',
    visible: true,
    order: 4,
    filterable: false,
    sortable: false,
    maxWidth: 50,
  },
});
