<template>
  <div class="market-groups-root">
    <Modal
      class="market-groups-config-modal"
      :visible="editModalVisible"
      title="Market group configuration"
      no-close-btn
      @close="onEditModalClose"
    >
      <EditMarketGroupModalContent
        :market-group-data="selectedRow || { sportId: selectedSportId }"
        :is-create="isCreate"
        @close-modal="onEditModalClose"
        @data-update="onDataUpdate"
      />
    </Modal>
    <SportSidebar
      title="Sports"
      :disabled="isDataLoading"
      :selected-sport-id="selectedSportId"
      @update:selectedSportId="updateSelectedSport"
    />
    <div
      :class="[
        'market-groups-content',
        { 'sidebar-collapsed': sidebarCollapsed },
      ]"
    >
      <header class="market-groups-header">
        <Heading>
          Market group configuration
        </Heading>
        <div class="filter-root">
          <TextInput
            :model-value="searchValue"
            @update:model-value="updateSearchValue"
            icon="search"
            :placeholder="'Search market groups'"
            small
          />
          <Button
            class="create-new-record-btn"
            variant="primary"
            icon="plus"
            @click="createNewMarketGroup"
          >
            Create new market group
          </Button>
        </div>
      </header>
      <div class="market-groups-body">
        <div v-if="!isDataLoading">
          <div class="market-groups-body-root">
            <Table
              class="market-groups-table"
              :columns="tableColumns"
              :rows="filteredMarketDisplayConfigurations"
              :no-data-message="'No available market display configurations'"
              @row-clicked="onRowEdit"
            >
              <template #cell="{ column, row }">
                <TableCell
                  :column-key="column.key"
                  :row="row"
                  @data-update="onDataUpdate"
                />
              </template>
            </Table>
          </div>
        </div>
        <Spinner v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  debounce, includes, toLower, filter, reduce, cloneDeep,
} from 'lodash';
import Heading from '@/components/common/Heading';
import SportSidebar from '@/components/common/SportSidebar';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';
import sportIds from '@/services/helpers/sports';
import { marketGroupsConfigurationsTableColumns } from '@/services/helpers/market-groups-table-helper';
import Table from '@/components/common/Table';
import TableCell from './table-config/TableCell';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import EditMarketGroupModalContent from './modal/EditMarketGroupModalContent';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Heading,
    SportSidebar,
    Spinner,
    TextInput,
    Table,
    TableCell,
    Modal,
    Button,
    EditMarketGroupModalContent,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isDataLoading = ref(false);
    const selectedSportId = ref(FOOTBALL_ID);
    const searchValue = ref('');
    const editModalVisible = ref(false);
    const selectedRow = ref();
    const isCreate = ref(false);

    const allMarketGroups = ref([]);

    const tableColumns = ref(reduce(
      marketGroupsConfigurationsTableColumns(),
      (fields, values, key) => [
        ...fields,
        {
          ...values,
          key,
        },
      ],
      [],
    ));

    const sidebarCollapsed = computed(() => !store.getters.sidebarExpanded);

    const updateSearchValue = debounce((val) => {
      searchValue.value = val;
      document.getElementById('table-scroller').scrollTo(0, 0);
    }, 300);

    const loadMarketGroups = (sportId, rememberScrollLocation = false) => {
      isDataLoading.value = true;
      const tableScrollerPosition = document.getElementById('table-scroller').scrollTop;
      store.dispatch('loadAllMarketGroupsList', sportId)
        .then((data) => {
          isDataLoading.value = false;
          allMarketGroups.value = data;

          if (rememberScrollLocation) {
            setTimeout(() => {
              document.getElementById('table-scroller').scrollTo(0, tableScrollerPosition);
            }, 0);
          }
        })
        .catch(() => {
          isDataLoading.value = false;
          allMarketGroups.value = [];
        });
    };

    const filteredMarketDisplayConfigurations = computed(() => {
      if (!searchValue.value) return allMarketGroups.value;
      return filter(allMarketGroups.value,
        (market) => includes(toLower(market.code), toLower(searchValue.value))
        || includes(toLower(market.displayName), toLower(searchValue.value)));
    });

    const updateSelectedSport = (sportId) => {
      selectedSportId.value = sportId;
      loadMarketGroups(sportId);
      const query = cloneDeep(route.query);
      router.push({
        name: route.name,
        query: {
          ...query,
          sport: sportId,
        },
      });
    };

    const onRowEdit = (row) => {
      selectedRow.value = row;
      isCreate.value = false;
      editModalVisible.value = true;
    };
    const createNewMarketGroup = () => {
      isCreate.value = true;
      selectedRow.value = null;
      editModalVisible.value = true;
    };
    const onEditModalClose = () => { editModalVisible.value = false; };
    const onDataUpdate = (sportId) => loadMarketGroups(sportId, true);

    onMounted(() => {
      updateSelectedSport(route.query.sport || FOOTBALL_ID);
    });

    return {
      isDataLoading,
      selectedSportId,
      searchValue,
      updateSearchValue,
      updateSelectedSport,
      filteredMarketDisplayConfigurations,
      tableColumns,
      sidebarCollapsed,
      editModalVisible,
      selectedRow,
      isCreate,
      onEditModalClose,
      onRowEdit,
      onDataUpdate,
      createNewMarketGroup,
    };
  },
};
</script>

<style lang="scss">
  .market-groups-root {
    .market-groups-config-modal {
      .modal__dialog {
        min-width: 550px;
        max-width: 650px;
        .modal__header {
          padding: 18px 16px 22px 16px;
          .modal__title {
            padding: 0;
          }
        }
      }
    }
    .market-groups-content {
      width: calc(100% - #{$sidebarWidth});
      height: 100%;

      &.sidebar-collapsed {
        width: calc(100% - #{$sidebarWidthCollapsed});
      }

      .market-groups-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        .filter-root {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .market-groups-body {
        .table {
          .table-row {
            cursor: pointer;
          }
        }
        .market-groups-body-root {
          header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
          }
          .market-groups-table {
            // Navbar height - 69px
            // Header height - 86px
            height: calc(100vh - 69px - 86px);
          }
        }
      }
    }
    .create-new-record-btn {
        .icon {
          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
  }
</style>
