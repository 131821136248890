<template>
  <div
    :class="[
      'toggle',
      { 'toggle--active': modelValue },
      { 'toggle--is-disabled': disabled },
    ]"
    @keydown="toggle"
    @click.stop="toggle"
  >
    <div class="toggle-knob" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  setup(props, { emit }) {
    const toggle = () => {
      if (props.disabled) return;
      emit('update:modelValue', !props.modelValue);
    };

    return {
      toggle,
    };
  },
};
</script>

<style lang="scss">
.toggle {
  width: 32px;
  height: 16px;
  background-color: #CDCDCD;
  border-radius: 40px;
  padding: 2px;
  position: relative;
  cursor: pointer;
  transition: background-color 100ms ease-in;

  .toggle-knob {
    background-color: #FFFFFF;
    width: 12px;
    height: 12px;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    transition: left 100ms ease-in;
  }

  &.toggle--active {
    background-color: #003C3C;

    .toggle-knob {
      left: calc(100% - 12px - 2px);
    }
  }

  &--is-disabled {
    opacity: 0.5;
  }
}
</style>
