<template>
  <span
    class="table-label-cell"
    v-html="value"
  />
</template>

<script>
import { computed } from 'vue';
import { isNil } from 'lodash';

export default {
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const value = computed(() => (!isNil(props.row?.[props.columnKey]) ? props.row?.[props.columnKey] : '-'));

    return {
      value,
    };
  },
};
</script>
<style lang="scss">
  .table-label-cell {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
