<template>
  <Sidebar
    :title="title"
    :expanded="expanded"
    @update:expanded="setExpanded"
    @onSearch="onSportSearch"
  >
    <Spinner v-if="sportsLoading" />

    <SidebarButton
      v-for="sport in filteredSports"
      :key="sport.sportId"
      :selected="selectedSportId === sport.sportId"
      :disabled="disabled"
      @click="updateSelectedSport(sport)"
    >
      {{ sport.sportName }}
    </SidebarButton>
  </Sidebar>
</template>

<script>
import {
  find, includes, filter, toLower,
} from 'lodash';
import {
  ref, onMounted, watch, computed,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import * as api from '@/services/api';
import Sidebar from '@/components/common/Sidebar';
import SidebarButton from '@/components/common/SidebarButton';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    Sidebar,
    SidebarButton,
    Spinner,
  },
  props: {
    title: {
      type: String,
      default: 'Sports',
    },
    selectedSportId: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:selectedSportId': {
      type: String,
    },
    sportUpdated: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const sports = ref([]);
    const sportsLoading = ref(false);
    const sportsSearchValue = ref('');
    const route = useRoute();
    const allSportsUnsupported = computed(() => includes(route.name, 'Mapping') || includes(route.name, 'ManualEdit') || route.name === 'market-display' || route.name === 'market-groups' || route.name === 'selection-display');
    const filteredSports = computed(() => filter(sports.value, (sport) => {
      if (allSportsUnsupported.value) return sport.sportId && includes(toLower(sport.sportName), toLower(sportsSearchValue.value));
      return includes(toLower(sport.sportName), toLower(sportsSearchValue.value));
    }));

    const updateSelectedSport = (sport) => {
      emit('update:selectedSportId', sport.sportId);
      emit('sportUpdated', sport);
    };

    const onSportSearch = (searchValue) => {
      sportsSearchValue.value = searchValue;
    };

    onMounted(() => {
      sports.value = [];
      sportsLoading.value = true;
      api.findAllSports()
        .then((allSports) => {
          sports.value = filter(allSports, (sport) => sport.sportCoverageAvailablesBySportId.nodes?.[0].available);
          const foundSport = find(sports.value, { sportId: props.selectedSportId });
          emit('sportUpdated', foundSport);
        })
        .finally(() => {
          sportsLoading.value = false;
        });
    });

    watch(() => props.selectedSportId, (newValue) => {
      const foundSport = find(sports.value, { sportId: newValue });
      emit('sportUpdated', foundSport);
    });

    const expanded = computed(() => store.getters.sidebarExpanded);
    const setExpanded = (newExpanded) => {
      store.dispatch('setSidebarExpanded', newExpanded);
    };

    return {
      filteredSports,
      sportsLoading,

      updateSelectedSport,
      onSportSearch,

      expanded,
      setExpanded,
    };
  },
};
</script>
