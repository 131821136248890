<template>
  <component
    :is="componentName"
    :column-key="columnKey"
    :row="row"
    @updateValue="updateValue"
  />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import DefaultCell from './DefaultCell';
import TextInputCell from './TextInputCell';
import ToggleCell from './ToggleCell';
import ActionsCell from './ActionsCell';

const components = {
  default: DefaultCell,
  displayName: TextInputCell,
  position: TextInputCell,
  active: ToggleCell,
  actions: ActionsCell,
};

export default {
  emits: ['dataUpdate'],
  components,
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const componentName = computed(() => components[props.columnKey] || 'default');

    const updateValue = (payload) => {
      store.dispatch('updateMarketGroup', payload).then(() => {
        emit('dataUpdate', payload.sportId);
        store.dispatch('addNotification', {
          message: 'Successfully updated market group',
          type: 'success',
          duration: 2000,
        });
      }).catch(() => {
        store.dispatch('addNotification', {
          message: 'Unable to update market group',
          type: 'error',
          duration: 2000,
        });
      });
    };

    return {
      componentName,
      updateValue,
    };
  },
};
</script>
