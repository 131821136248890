<template>
  <div class="edit-market-group-modal-root">
    <div
      class="edit-market-group-modal-content"
    >
      <div
        v-if="confirmDeleteActive"
        class="disabled-layer-root"
      />
      <div
        class="edit-market-group-modal-content-section"
      >
        <div class="market-group-info-title">
          Market group information
        </div>
        <div class="market-group-info">
          <div class="market-group-info-label">
            <p>Group code</p>
          </div>
          <TextInput
            :model-value="data.code"
            no-clear-button
            :readonly="!isCreate"
            :placeholder="'Input group code'"
            @update:modelValue="onCodeChange"
          />
          <div class="market-group-info-label">
            <p>Display name</p>
          </div>
          <TextInput
            :model-value="data.displayName"
            :placeholder="'Input display name'"
            no-clear-button
            @update:modelValue="onDisplayNameChange"
          />
          <div class="market-group-info-label">
            <p>Position</p>
          </div>
          <TextInput
            :model-value="data.position"
            :placeholder="'Input position'"
            no-clear-button
            @update:modelValue="onPositionChange"
          />
          <div class="toggle-item">
            <div class="toggle-item-content">
              <Toggle
                :model-value="data.active"
                @update:model-value="toggleIsGroupActive"
              />
              <span class="toggle-label">Active</span>
            </div>
          </div>
        </div>
        <div class="market-group-info-title">
          <p>Markets assigned to market group</p>
        </div>
        <div>
          <AddMarketToGroupSearch
            @onMarketAdd="addMarketToGroup"
            :group-market-codes="groupMarketCodes"
          />
        </div>
        <div
          v-if="groupMarketCodes.length"
          class="group-markets"
        >
          <div
            v-for="marketCode in groupMarketCodes"
            :key="marketCode"
            class="market-item"
          >
            <div class="market-info">
              <span>{{ marketCode }}</span>
            </div>
            <div class="market-actions">
              <div class="remove-market-btn">
                <Icon
                  @click="removeMarketFromGroup(marketCode)"
                  name="trash"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="no-markets-data"
        >
          No markets data
        </div>
      </div>
    </div>
    <div
      v-if="!confirmDeleteActive"
      class="market-group-actions"
    >
      <div class="left-side">
        <div
          v-if="!isCreate"
          class="delete-btn"
          @click="toggleConfirmDelete"
        >
          Delete market group
        </div>
      </div>
      <div class="right-side">
        <div
          @click="closeModal"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          class="save-btn"
          :variant="'primary'"
          @click="onSaveChanges"
          :disabled="isSubmitDisabled"
        >
          Save
        </Button>
      </div>
    </div>
    <div
      v-else
      class="confirm-delete"
    >
      <div class="left-side">
        Confirm delete
      </div>
      <div class="right-side">
        <div
          @click="toggleConfirmDelete"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'danger'"
          @click="deleteMarketGroup"
        >
          Delete
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  map, cloneDeep, filter, isEqual, isNaN,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Toggle from '@/components/common/Toggle';
import AddMarketToGroupSearch from './AddMarketToGroupSearch';

export default {
  emits: ['closeModal', 'dataUpdate'],
  props: {
    marketGroupData: {
      type: Object,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    TextInput,
    Icon,
    Button,
    Toggle,
    AddMarketToGroupSearch,
  },
  setup(props, { emit }) {
    const store = useStore();
    const originalData = ref(cloneDeep({
      ...{ active: false }, // For creating a new market group model
      ...props.marketGroupData,
      marketCodes: props.marketGroupData?.marketCodes || [],
    }));
    const data = ref(cloneDeep(originalData.value));
    const groupMarketCodes = computed(() => map(data.value.marketCodes));
    const marketToEdit = ref(null);
    const isInvalidModel = () => {
      let modelInvalid = false;
      const { code, position, displayName } = data.value;
      const wholeNumberRegex = /^-?\d+$/;
      if (
        !code
        || !displayName
        || !wholeNumberRegex.test(position)
      ) {
        modelInvalid = true;
      }
      return modelInvalid;
    };
    const isSubmitDisabled = computed(() => isEqual(originalData.value, data.value) || isInvalidModel());
    const confirmDeleteActive = ref(false);

    const onDisplayNameChange = (val) => { data.value.displayName = val; };
    const onCodeChange = (val) => { data.value.code = val; };
    const onPositionChange = (val) => {
      const valueParsed = parseInt(val, 10);
      data.value.position = isNaN(valueParsed) ? null : valueParsed;
    };

    const toggleIsGroupActive = () => { data.value.active = !data.value.active; };

    const removeMarketFromGroup = (marketCode) => {
      data.value.marketCodes = filter(data.value.marketCodes, (code) => code !== marketCode);
    };

    const addMarketToGroup = (marketCode) => {
      data.value.marketCodes.push(marketCode);
    };
    const toggleConfirmDelete = () => { confirmDeleteActive.value = !confirmDeleteActive.value; };
    const closeModal = () => { emit('closeModal'); };
    const onSaveChanges = () => {
      if (props.isCreate) {
        data.value.operatorId = localStorage.getItem('operator');
      }
      store.dispatch('updateMarketGroup', data.value).then(() => {
        const message = `Market group successfully ${props.isCreate ? 'created' : 'updated'}.`;
        store.dispatch('addNotification', {
          message,
          type: 'success',
        });
        closeModal();
        emit('dataUpdate', data.value.sportId);
      }).catch(() => {
        const message = `Unable to ${props.isCreate ? 'create' : 'update'} market group.`;
        store.dispatch('addNotification', {
          message,
          type: 'error',
        });
      });
    };
    const deleteMarketGroup = () => {
      store.dispatch('removeMarketGroup', data.value.marketGroupId).then(() => {
        closeModal();
        emit('dataUpdate', data.value.sportId);
      });
    };

    return {
      data,
      groupMarketCodes,
      marketToEdit,
      isSubmitDisabled,
      confirmDeleteActive,
      onCodeChange,
      onDisplayNameChange,
      onPositionChange,
      toggleIsGroupActive,
      removeMarketFromGroup,
      onSaveChanges,
      closeModal,
      addMarketToGroup,
      toggleConfirmDelete,
      deleteMarketGroup,
    };
  },
};
</script>

<style lang="scss">
  .edit-market-group-modal-root {
    .edit-market-group-modal-content {
      position: relative;
      padding: 16px;
      .disabled-layer-root {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);
        z-index: 1;
      }
      .edit-team-tabs {
        display: flex;
        align-items: center;
        .edit-team-tab-item {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px;
          font-size: 14px;
          line-height: 16px;
          border-bottom: 2px solid $gray500;
          cursor: pointer;

          &.selected {
            border-bottom: 2px solid $brandPrimary500;
          }
        }
      }
      .edit-market-group-modal-content-section {
        width: 100%;
        padding-bottom: 32px;
      }
    }
    input {
      height: auto;
    }
    .market-group-info-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 16px;
    }
    .market-group-info-label {
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
      color: $gray800;
      padding-bottom: 4px;
      margin-top: 16px;

      &.small {
        padding: 0;
        text-transform: capitalize;
      }
    }
    .market-group-info {
      padding-bottom: 32px;

      .text-field,
      .market-group-info-item {
        width: 100%;

        input:read-only {
          background-color: $gray400;
          color: $gray700;
        }
      }

      .toggle-item {
        padding-top: 20px;
        .toggle-item-content {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          gap: 4px;
          padding: 12px 16px;
          background-color: $gray300;
          .toggle-label {
            width: auto;
            font-size: 14px;
          }
        }
      }
    }
    .group-markets {
      max-height: 250px;
      overflow: auto;
      padding-left: 1px;

      .market-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        cursor: pointer;
        .market-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-right: 8px;
        }
        span {
          display: inline-block;
          padding: 4px 0;
        }

        .market-actions {
          gap: 8px;
          align-items: center;
          .remove-market-btn {
            width: 18px;
            height: 18px;
            cursor: pointer;
            .icon {
              svg {
                path {
                  fill: $error500;
                }
              }
            }
          }
        }
      }
    }
    .no-markets-data {
      min-height: 150px;
      text-align: center;
      padding: 32px 0;
      color: $gray800;
    }
    .market-group-actions,
    .confirm-delete {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      padding: 16px;
      border-top: 1px solid $gray300;

      .save-btn {
        padding: 0 24px;
      }
      .left-side,
      .right-side {
        width: 50%;
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .delete-btn,
      .dismiss-btn {
        cursor: pointer;
      }
      .delete-btn {
        color: $error500;
      }
    }
  }
</style>
