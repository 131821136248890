<template>
  <div
    ref="searchRoot"
    class="add-market-to-group-search"
  >
    <div class="market-search-header">
      <TextInput
        class="search-field"
        :model-value="marketSearch"
        icon="search"
        placeholder="Search market codes"
        small
        @update:modelValue="updateSearch"
        @focus="onMarketSearchInputFocus"
      />
    </div>
    <div :class="['market-search-body', { 'visible': marketSearchListVisible }]">
      <Spinner
        v-if="marketsLoading"
        small
      />
      <p v-if="!marketsLoading && !marketCodes.length">
        No markets found
      </p>
      <div
        v-if="!marketsLoading && marketCodes.length"
        class="market-items"
      >
        <div
          v-for="marketCode in marketCodes"
          :key="marketCode"
          class="market-item"
          @click="addMarketToGroup(marketCode)"
        >
          <div>{{ marketCode }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import {
  debounce, filter, includes,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import TextInput from '@/components/common/TextInput';
import Spinner from '@/components/common/Spinner';

export default {
  components: {
    TextInput,
    Spinner,
  },
  emits: ['onMarketAdd'],
  props: {
    groupMarketCodes: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const searchRoot = ref(null);
    const store = useStore();
    const marketSearch = ref('');
    const marketCodes = computed(() => filter(
      store.getters.marketGroupMarketCodeSearchList,
      (marketCode) => !includes(
        props.groupMarketCodes,
        marketCode,
      ),
    ));
    const marketsLoading = computed(() => store.getters.marketGroupMarketCodeSearchListLoading);
    const marketSearchListVisible = ref(false);

    const loadMarketGroupMarketsCodeList = (searchValue, groupMarketCodes) => {
      store.dispatch('loadAllMarketCodes', {
        searchValue,
        marketCodes: groupMarketCodes,
      });
    };

    const updateSearch = debounce((val) => {
      marketSearch.value = val;
      loadMarketGroupMarketsCodeList(val, props.groupMarketCodes);
    }, 500);

    const onMarketSearchInputFocus = () => { marketSearchListVisible.value = true; };

    const addMarketToGroup = (marketCode) => { emit('onMarketAdd', marketCode); };

    onClickOutside(searchRoot, () => {
      marketSearchListVisible.value = false;
    });

    onMounted(() => loadMarketGroupMarketsCodeList('', props.groupMarketCodes));

    return {
      searchRoot,
      marketSearch,
      marketCodes,
      marketsLoading,
      updateSearch,
      marketSearchListVisible,
      onMarketSearchInputFocus,
      addMarketToGroup,
    };
  },
};
</script>

<style lang="scss">
.add-market-to-group-search {
  background-color: $white;
  position: relative;
  border-radius: 4px;

  .market-search-header {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $white;
  }
  .market-search-body {
    width: 100%;
    height: 250px;
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(100% + 2px);
    z-index: 10;
    background-color: $white;
    border: 1px solid $gray500;
    box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.12);

    &.visible {
      display: flex;
    }

    .market-items {
      width: 100%;
      overflow: auto;
      height: 100%;

      .market-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding: 8px;
        min-height: 50px;
        cursor: pointer;
        .add-player-to-squad-btn {
          display: none;
        }

        &:hover {
          background-color: $gray300;
          .add-player-to-squad-btn {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
