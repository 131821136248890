<template>
  <div
    @click.stop
    class="table-checkbox-cell"
  >
    <Toggle
      :model-value="value"
      @update:model-value="updateModelValue"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import Toggle from '@/components/common/Toggle';

export default {
  emits: ['updateValue'],
  components: {
    Toggle,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const value = computed(() => props.row?.[props.columnKey]);

    const updateModelValue = () => {
      emit('updateValue', {
        ...props.row,
        [props.columnKey]: !value.value,
      });
    };

    return {
      value,
      updateModelValue,
    };
  },
};
</script>
<style lang="scss">
  .table-checkbox-cell {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
