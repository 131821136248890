<template>
  <div
    class="table-actions-cell"
  >
    <Icon
      :name="'pencil'"
    />
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
  },
  setup() {
  },
};
</script>
<style lang="scss">
  .table-actions-cell {
    width: 100%;
    display: flex;
    .icon {
      width: 16px;
      height: 16px;
      svg {
        path {
          fill: $gray600;
        }
      }
    }
  }
</style>
